import * as React from "react"

import styled from "styled-components"

import Layout from "@/components/Layout"
import Seo from "@/components/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />

    <Wrapper404>
      <h1>404</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Wrapper404>
  </Layout>
)

export default NotFoundPage

const Wrapper404 = styled.div`
  height: 100vh;
  grid-column: 1 / span 4;
  ${({ theme }) => theme.media.tablet} {
    grid-column: 3 / span 2;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: ${({ theme }) => theme.colors.darkGray};

  h1 {
    font-size: 200px;
    margin: 20px;
  }

  p {
    font-size: 24px;
    margin: 20px;
    text-align: center;
  }
`
